.doctor-account-block {
  width: 100%;
  max-width: 375px;
  margin: 116px auto 81px;
}

.doctor-info-block {
  display: flex;
  width: 100%;
  min-height: 134px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #00000014;
}

.doctor-info-image-block {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

.doctor-info-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.doctor-info-service-block {
  width: 100%;
  display: flex;
  margin: 16px 0px;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-between;
}

.doctor-info-service-item {
  display: flex;
  width: 164px;
  height: 94px;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px #00000014;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.doctor-info-analize-block {
  display: flex;
  width: 100%;
  min-height: 134px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #00000014;
}

.doctor-info-analize-icon-block {
  width: 24px;
  height: 24px;
}

.doctor-info-analize-icon {
  width: 100%;
  height: 100%;
}

.text-block {
  width: 100%;
  margin-top: 16px;
}
