.socialsDiv {
    margin-top: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 32px;
}

.socialsDiv img {
    height: 24px;
    object-fit: cover;
    margin-right: 30px;
}