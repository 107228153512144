.category-horizontal {
    display: flex;
    overflow: auto;
    width: 100%;
    max-width: 375px;
    column-gap: 32px;
    row-gap: 15px;
    margin: 0px auto 27px;
    white-space: nowrap;
    scrollbar-width: none;
}

.mytitle {
    word-wrap: break-word;
}