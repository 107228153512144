.applications-card-block {
  display: inline-flex;
  width: 311px;
  padding: 16px;
  row-gap: 16px;
  flex-shrink: 0;
  height: fit-content;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.applications-card-item-child {
  margin-bottom: 8px;
}

.applications-card-item-child:last-child {
  margin-top: 15.5px;
  margin-bottom: 0px;
}

.applications-card-type-text {
  color: var(--colors-2-1);
}

.applications-card-pacient-name {
  color: var(--colors-4-2);
}

.applications-card-item-child-location-block {
  display: flex;
  width: 19.2px;
  height: 19.2px;
  float: left;
  margin-right: 10.4px;
}

.applications-card-item-child-location-text {
  color: var(--colors-4-2);
}

.applications-card-item-date-time-text {
  color: var(--colors-4-2);
}

.applications-card-item-date-time-block {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}

.applications-card-item-date-time-child {
  display: flex;
  width: 132px;
  gap: 10px;
  padding: 12.5px 8px;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.applications-card-item-date-time-child-text {
  color: var(--colors-4-5);
}

.applications-card-item-service-type-price {
  display: flex;
  padding: 16px;
  align-items: center;
  background-color: var(--white);
  justify-content: space-between;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.applications-card-item-service-type-text {
  max-width: 138px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-4-2);
}

.applications-card-item-price-text {
  color: var(--colors-2-1);
}

.applications-card-buttons-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applications-card-buttons-item {
  width: 117px;
  height: 40px;
}

.applications-card-buttons-item:last-child {
  width: 146px;
}
