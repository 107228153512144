.facebook {
  width: 12px;
  height: 24px;
  margin-left: 6px;
  margin-right: 30px;
  margin-bottom: 16px;
}

.instagram {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.twitter {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.whatsapp {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.viber {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.telegram {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
}

.hrBorder {
  border: 0.5px solid #c4c4c4;
}

.phoneIconSocialBlock {
  width: 14.4px;
  height: 14.4px;
  margin-right: 4.8px;
  margin-left: 15px;
}

.phoneNumberSocialBlock {
  font-family: Monsterat;
  width: 124px;
  font-size: 12px;
}

.marginTop {
  margin-top: -9px;
  padding-top: 16px;
  padding-bottom: 5;
}

.phoneDiv {
  width: 148px;
  display: flex;
  align-items: center;
  padding: 0px;
}


.iconsDiv svg {
  margin-top: 15px;
}
