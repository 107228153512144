.select-selected-block {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}

.select-options-radio {
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.select-options-radio-center {
  width: 10px;
  height: 10px;
  background-color: '#152534';
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.select-selected-arrow {
  width: 24px;
  height: 24px;
}

.select-options-block {
  row-gap: 16px;
  flex-direction: column;
}

.select-options-item {
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
}
