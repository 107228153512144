.input-component-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-wrap-block {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.input-label {
  text-align: left;
  color: var(--colors-4-4);
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.input::placeholder {
  color: var(--colors-4-4);
}

.input-fonts {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  font-family: Monsterat;
}

.eye-block {
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.error-block {
  margin-top: 4px;
}

.error-text {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  font-family: Monsterat;
  color: var(--colors-3-2);
}

.dynamic-unit-block {
  top: 0px;
  right: 0px;
  width: 70px;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
}

.dynamic-unit {
  color: var(--colors-4-2)
}
