.add-services-item {
  width: 100%;
  padding: 4px;
  display: flex;
  flex-shrink: 0;
  min-height: 48px;
  max-width: 343px;
  border-bottom: 0.5px solid var(--colors-4-7);
  justify-content: space-between;
}

.add-services-item-name {
  display: flex;
  margin-right: 10px;
  align-items: center;
  color: var(--colors-4-2);
}

.add-services-item-name p {
  line-height: 18px;
}

.add-services-item-price {
  width: 90px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: var(--colors-2-1);
  justify-content: space-between;
}
