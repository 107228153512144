.clinicRow {
    margin-bottom: 30px;
    margin-top: 24px;
}

.clinicRow .logo {
    height: 60px;
    object-fit: cover;
    margin-bottom: 8px;
}

.clinicRow h2.title {
    font-weight: 600;
    font-size: 16px;
}

.workplaceWithDoctorsDiv {
    margin: 60px auto 82px;
}
