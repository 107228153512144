.myCalendar .react-calendar {
  border: none;
  margin: 0px auto;
}

.myCalendar .calendarArrows {
  height: 14px;
  width: 8px;
}

.myCalendar .react-calendar__navigation button,
.myCalendar .react-calendar__navigation button:active,
.myCalendar .react-calendar__navigation:focus .myCalendar .react-calendar__navigation button:hover {
  background-color: #fff;
}

.myCalendar .react-calendar__navigation button:disabled {
  background-color: #fff !important;
}

.myCalendar .react-calendar__navigation__label__labelText {
  color: #152534;
  font-size: 16px;
  font-weight: 530;
}

.myCalendar .react-calendar__navigation {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 17px;
}

.myCalendar .react-calendar__month-view__days__day {
  color: #152534;
  font-weight: 600;
  line-height: 17px;
  padding: 15px;
}

.myCalendar .react-calendar__month-view__days__day--weekend {
  color: #A6A6A6;
}

.myCalendar .react-calendar__month-view__weekdays__weekday {
  color: #218cc2;
  margin-bottom: 15px;
}

.myCalendar .react-calendar__tile--active {
  background: #218CC0;
  border-radius: 100px;
  color: #fff;
}

.myCalendar .react-calendar__tile:disabled {
  color: #A6A6A6;
  background: #fff;
}

.activeTime {
  background-color: #218CC0 !important;
  color: #fff !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.disabledTime {
  background-color: gray;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.highlight {
  background-color: #218CC0 !important;
  border-radius: 100px;
  color: #fff !important;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.highlight:hover {
  background-color: #218CC0 !important;
  border-radius: 100px;
  color: #fff !important;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
