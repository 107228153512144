.side-settings-block {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding-top: 56px;
  z-index: 1035;
  box-sizing: border-box;
  background-color: rgba(21, 37, 52, 0.5);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.side-settings-rel-block {
  position: relative;
  width: 100%;
  height: 100%;
}

.side-settings-abs-block {
  position: absolute;
  display: flex;
  top: 0px;
  width: 231px;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 60px 16px 56px;
  justify-content: space-between;
  border-radius: 8px 0px 8px 8px;
  background-color: var(--white);
  -webkit-border-radius: 8px 0px 8px 8px;
  -moz-border-radius: 8px 0px 8px 8px;
  -ms-border-radius: 8px 0px 8px 8px;
  -o-border-radius: 8px 0px 8px 8px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.side-settings-info-block {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100% - 141px);
}

.side-settings-info-item {
  width: 100%;
  height: 56px;
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px #00000014;
}

.side-settings-info-item-content {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
}

.side-settings-info-item-content-image-block {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 16px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.side-settings-info-item-content-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.side-settings-logout-block {
  width: 100%;
  margin-top: 16px;
}

.side-settings-logout-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: Monsterat;
  color: var(--colors-1-1);
  text-decoration: underline;
}

.side-settings-policy-languages-block {
  width: 100%;
  height: 141px;
}

.side-settings-policy-menu {
  list-style: none;
  padding: 0px;
}

.side-settings-policy-menu-item {
  padding-bottom: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-bottom: 0.5px solid var(--colors-4-7);
}

.side-settings-policy-menu-item-link {
  text-decoration: none;
  color: var(--colors-4-2);
}

.side-settings-languages-block {
  width: 100%;
  margin-top: 8px;
}
