.indicator-block {
  width: 295px;
  flex-shrink: 0;
  padding: 16px;
  box-sizing: border-box;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.indicator-date {
  margin-bottom: 16px !important;
  color: var(--colors-2-1);
}

.indicator-desc-block {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}

.indicator-desc {
  display: flex;
  justify-content: space-between;
}

.indicator-desc-key {
  color: var(--colors-4-2);
}

.indicator-desc-value {
  color: var(--colors-2-1);
}
