@import url('colors.css');

.button {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  border-radius: 20px;
  font-family: Monsterat;
  color: var(--colors-1-1);
  text-decoration: underline;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: transparent;
}

.btn-bg-color {
  color: var(--white);
  text-decoration: none;
  background-color: var(--colors-1-1);
}

.btn-disabled-bg-color {
  background-color: var(--colors-4-6);
}
