.searchBlock {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-top: 10px;
    text-align: center;
    height: 80px;
    position: relative;
}

.searchBlock.active {
    background-color: #218CC0;
}

.searchBlock.active h4 {
    color: #fff;
}

.searchCarousel li {
    margin-right: 16px;
}

.searchBlock img {
    max-width: 20px;
}

.searchBlock h4 {
    font-weight: 400;
    font-size: 12px;
    color: #152534;
    margin-bottom: 0;
    position: absolute;
    bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    width: 117px;
}

.removeIcon {
    width: 14px;
    height: 14px;
    margin-top: 6px;
    margin-right: 6px;
}

.searchInputGroup {
    border-bottom: 0.5px solid #152534;
}

.searchCarousel {
    overflow-x: scroll;
}

.searchCarousel::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .searchCarousel {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .selectedFilters {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: 12px;
    color: #152534;
    padding: 2px 8px;
    margin-right: 5px;
  }

  .removeBlack {
    width: 11px;
    height: 11px;
    margin-left: 18px;
  }

  .searchLink {
    display: block;
    width: 100%;
    height: 100%;
  }