.add-services-search-place {
  margin-top: 80px;
}

.add-services-parent-block {
  display: flex;
  width: 100%;
  row-gap: 8px;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 210px);
}

.add-services-btn-place {
  position: fixed;
  left: 0px;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.add-services-btn-block {
  width: 165px;
  height: 40px;
}