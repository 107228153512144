:root {
  --white: #ffffff;

  --colors-1-1: #218CC0;
  --colors-1-2: #2AA1DA;

  --colors-2-1: #50D100;

  --colors-3-1: #D10000;
  --colors-3-2: #F70000;

  --colors-4-2: #152534;
  --colors-4-3: #4D4D4D;
  --colors-4-4: #6A6A6A;
  --colors-4-5: #888888;
  --colors-4-6: #A6A6A6;
  --colors-4-7: #C4C4C4;
}
