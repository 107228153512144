.header-block {
    margin-top: 40px;
}

.subheader {
    /* width: 375px; */
    min-height: 56px;

    border-radius: 0px 0px 8px 8px;
    background: #ffffff;
}

.subheader .box-shadow {
    box-shadow: 0px 2px 4px 0px rgba(52, 52, 52, 0.05);
}

.container1 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.slack {
    width: 8px;
    height: 14px;
}

.headerTitle {
    font-family: Monsterat;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #152534;
    padding-top: 16px;
}

.search1 {
    width: 20px;
    height: 20px;
}

.img11 {
    margin-left: 17px;
    margin-top: 15px;
}
