.diagnos-card {
  display: flex;
  width: 128px;
  height: 77px;
  padding: 8px;
  row-gap: 16px;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-sizing: border-box;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.diagnos-card:hover {
  background-color: var(--colors-1-1);
}

.diagnos-card:hover .diagnos-card-text {
  color: var(--white);
}

.diagnos-card-icon-block {
  width: 32px;
  height: 32px;
}

.diagnos-card-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.diagnos-card-text {
  text-align: center;
  color: var(--colors-4-2);
}
