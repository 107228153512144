.create-service-block {
  width: 100%;
  max-width: 375px;
  margin: 0px auto 24px;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 0.5px solid var(--colors-4-7);
}

.create-service-item {
  width: 100%;
  height: 40px;
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.create-service-icon-block {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.create-service-text-block {
  width: calc(100% - 96px);
}

.create-service-text {
  color: var(--colors-4-2);
}

.create-service-plus-block {
  display: flex;
  width: 44px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: var(--colors-1-1);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.create-service-back-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
