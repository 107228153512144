.headerWithLogo {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 85px;
  padding-top: 23px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(52, 52, 52, 0.05);
  border-radius: 0px 0px 8px 8px;
  z-index: 1040;
}

.headerWithoutLogo {
  position: fixed;
  top: 0px;
  left: 0px;
  padding-top: 23px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(52, 52, 52, 0.05);
  border-radius: 0px 0px 8px 8px;
  z-index: 1040;
}


.logoImg {
  height: 46px;
  margin: 5px 0 11px 16px;
}

.searchIcon {
  width: 20px;
  height: 20px;
}

.phoneIcon {
  height: 19px;
  width: 19px;
}

.header-icon-btn-place {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.header-icon-btn-block {
  display: flex;
  width: 64px;
  height: 24px;
  align-items: center;
  justify-content: space-between;
}

.header-icon-btn {
  display: flex;
  width: 24px;
  height: 24px;
  outline: none;
  cursor: pointer;
  padding: 0px;
  border: none;
  background-color: transparent;
}
