.objective-indicators-block {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}

.indicator-parent-block {
  display: flex;
  padding: 1px;
  column-gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  height: fit-content;
  white-space: nowrap;
  scrollbar-width: none;
}

