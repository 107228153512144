.box {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    overflow: hidden;
}

.activeTitle .box {
    background-color: #218CC0;
    color: #fff;
}

.disabledTitle .box {
    background-color: rgb(212, 211, 211);
    color: #fff;
}

.titleLink {
    font-size: 12px;
    color: #152534;
}

.box p.title {
    font-size: 12px;
    margin-bottom: 0;
}


