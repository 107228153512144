.advice-card-block {
  display: flex;
  width: 311px;
  height: auto;
  padding: 16px;
  column-gap: 8px;
  max-height: 279px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: var(--white, #FFF);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.advice-card-block-item {
  display: flex;
  width: 40px;
  height: 100%;
  flex-shrink: 1;
  max-height: 100%;
  align-items: flex-start;
  justify-content: center;
}

.advice-card-block-item:first-child {
  width: 183px;
  flex-shrink: 1;
  justify-content: flex-start;
}

.advice-card-block-item-desc {
  max-height: 100%;
  overflow-y: auto;
  word-break: break-all;
  white-space: normal;
  color: var(--colors-4-3);
}

.advice-card-block-item-img {
  width: 24px;
  height: 24px;
}
