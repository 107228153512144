.filterButtonDiv {
    height: 56px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    line-height: 56px;
    padding: 0 16px;
}

.filterimageSpan {
    float: right;
   
}

.filter-image {
    height: 24px;
    object-fit: cover;
}

.oneFilterBlock {
    padding: 25px 15px;
    border-bottom: 1px solid #c4c4c4;
}

.filterCheck input {
    width: 20px;
    height: 20px;
}

.filterCheck label {
    margin-left: 9px;
}