.medical-card-block {
  display: flex;
  width: 100%;
  padding: 16px;
  row-gap: 16px;
  max-width: 375px;
  margin: 0px auto 24px;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.medical-card-info-block {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.medical-card-info-title-block {
  display: flex;
  justify-content: space-between;
}

.medical-card-info-title-code {
  color: var(--colors-2-1);
}

.medical-card-info-title-text {
  color: var(--colors-1-2);
}

.medical-card-info-pacient-name {
  color: var(--colors-4-2);
}

.medical-card-info-services-and-price-block {
  display: flex;
  row-gap: 8px;
  margin-bottom: 8px;
  flex-direction: column;
}

.medical-card-info-services-and-price-item {
  display: flex;
  justify-content: space-between;
}

.medical-card-info-services-text {
  color: var(--colors-4-2);
}

.medical-card-info-price-text {
  color: var(--colors-2-1);
}

.medical-card-info-date-block {
  margin-bottom: 8px;
}

.medical-card-info-date-text {
  color: var(--colors-4-2);
}

.medical-card-info-status-block {
  display: flex;
  justify-content: space-between;
}

.medical-card-info-status-item {
  display: flex;
  width: 148px;
  height: 55px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.medical-card-info-status-date {
  color: var(--color-4-5);
}

.medical-card-info-status {
  color: var(--colors-2-1);
}
