.new-modal-block {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1036;
  display: flex;
  padding-top: 56px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  background-color: rgba(21, 37, 52, 0.5);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.new-modal-center {
  min-width: 343px;
  max-width: 90%;
  max-height: 90vh;
  padding: 0px 16px 24px;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.new-modal-header {
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 4px;
  justify-content: flex-end;
}

.new-modal-header-icon-block {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.new-modal-content {
  overflow-y: auto;
  width: 100%;
  height: auto;
  max-height: calc(100% - 108px);
}

.new-modal-footer {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}

.new-modal-footer-btn-block {
  width: 138px;
  height: 40px;
}

.new-modal-footer-btn-block:last-child {
  width: 165px;
}
