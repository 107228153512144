.histories-template-card-block {
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid;
  align-items: center;
  padding: 12.5px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.histories-template-card-text {
  color: var(--colors-4-5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
