.med-card-diagnos-block {
  display: flex;
  row-gap: 7px;
  padding: 8px;
  flex-shrink: 0;
  position: relative;
  width: fit-content;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.med-card-diagnos-close-block {
  position: absolute;
  top: -7px;
  right: -11px;
  width: 20px;
  height: 20px;
  align-items: center;
  border: 1px solid #fff;
  justify-content: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: var(--colors-1-1);
}

.med-card-diagnos-close {
  color: var(--white);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: Montserrat;
}

.med-card-diagnos-block-title {
  color: var(--colors-1-1);
}

.med-card-diagnos-block-code {
  color: var(--colors-4-2);
}

.med-card-diagnos-block-slug {
  color: var(--colors-4-2);
}
