.languages-block {
  height: 24px;
  padding: 0px !important;
}

.languages-item {
  width: 24px;
  float: left;
  height: 24px;
  display: flex;
  cursor: pointer;
  margin-right: 8px;
}

.languages-item:last-child {
  margin-right: 0px;
}
