.text-area-block {
  width: 100%;
  height: 95%;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  padding: 12.5px 16px;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.text-area-child-block {
  display: flex;
  column-gap: 10px;
}

.text-area-label {
  height: 30px;
}

.text-area {
  width: calc(100% - 34px);
  height: 100%;
  max-height: 100%;
  min-height: 30px;
  flex-shrink: 0;
  resize: none;
  border: none;
  outline: none;
  color: var(--colors-4-5);
}

.text-area-icon-block {
  width: 24px;
  height: 24px;
}

.text-area-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.text-area-error-message {
  color: var(--colors-3-2);
}
