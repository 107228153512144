@font-face {
  font-family: Monsterat;
  src: url("assets/fonts/monserat/Montserratarm-Regular.otf");
}

@import url('colors.css');

* {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: #152534;
}

p {
  margin-bottom: 0;
}

body {
  font-family: Monsterat;
  color: #152534;
}

.caption1-200 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  font-family: Monsterat;
}

.caption1-300 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: Montserrat;
}

.caption1-400 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Montserrat;
}


.caption2-100 {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: Montserrat;
}

.caption2-200 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Montserrat;
}

.caption2-300 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  font-family: Monsterat;
}

.caption2-400 {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  font-family: Monsterat;
}

.caption3-100 {
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: Montserrat;
}

.caption3-200 {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: Montserrat;
}

.body1-300-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: Monsterat;
}

.body2-300-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  font-family: Monsterat;
}

.body2-400-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-style: normal;
  font-family: Montserrat;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-11 {
  font-size: 11px !important;
}

.f-s-16 {
  font-size: 16px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.color-blue {
  color: #218CC0 !important;
}

.exit-link {
  margin-right: 7.5px;
  display: block;
  height: 22px;
  text-align: right;
  padding-bottom: 2px;
  padding-top: 7px;
}


h2.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #152534 !important;
}

.w20 {
  width: 20px;
}

.col-pd-r-7 {
  padding-right: 7.5px;
}

.col-pd-l-7 {
  padding-left: 7.5px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt16 {
  margin-top: 16px;
}

.mb10 {
  margin-bottom: 10px;
}

.mr16 {
  margin-right: 16px;
}

.mt8 {
  margin-top: 8px;
}

.mt0 {
  margin-top: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl0 {
  padding-left: 0;
}

.mb60 {
  margin-bottom: 60px;
}

.mt36 {
  margin-top: 36px;
}

.mt32 {
  margin-top: 32px;
}

.mb24 {
  margin-bottom: 24px;
}

.border-none {
  border: none !important;
}

.text-black {
  color: #152534 !important;
}

.text-grey {
  color: #6A6A6A !important;
}

.px15 {
  padding: 0 15px;
}

.position-r {
  position: relative;
}

.text-blue {
  color: #218CC0;
}

.h40 {
  height: 40px;
}

.mb40 {
  margin-bottom: 40px;
}

.h38 {
  height: 38px;
  overflow: hidden;
}

.float-left {
  float: left;
}

.lh40 {
  line-height: 40px;
}

.btn-blue,
.btn-blue:active,
.btn-blue:focus {
  background-color: #218CC0;
  color: #fff;
  border-radius: 20px;
  width: 100%;
}

.py11-5 {
  padding-top: 11.5px;
  padding-bottom: 11.5px;
}

.my32 {
  margin-top: 32px;
  margin-bottom: 32px;
}


.pb16 {
  padding-bottom: 16px;
}

.f-s-20 {
  font-size: 20px;
}

.float-right {
  float: right;
}

.mt44 {
  margin-top: 44px;
}

.mt40 {
  margin-top: 40px;
}

.pb-24 {
  padding-bottom: 24px;
}

.mb22 {
  margin-bottom: 22px;
}

.mt24 {
  margin-top: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb36 {
  margin-bottom: 36px;
}

.f-s-18 {
  font-size: 18px;
}

.w50 {
  width: 50%;
}

.w70 {
  width: 70%;
}

.w60 {
  width: 60%;
}

.pt-7 {
  padding-bottom: 5rem;
}

.text-green {
  color: #50D100 !important;
}

.mh180 {
  max-height: 180px;
  overflow: hidden;
}

.form-control:focus {
  box-shadow: none;
}


ul.autocompleteUl {
  max-height: 150px;
  list-style-type: none;
  overflow: scroll;
  position: relative;
  z-index: 1000;
  padding-left: 0px;
}

ul.autocompleteUl li {
  font-size: 14px;
  border-bottom: 1px solid grey;
  padding-bottom: 6px;
  padding-top: 6px;
}


.filterArrow {
  height: 8px;
  object-fit: cover;
}

.py12 {
  padding: 12px 0;
}

.mt-32 {
  margin-top: 32px;
}

.ml-11 {
  margin-left: 11px;
}

.mb-16{
  margin-bottom: 16px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-50 {
  margin-top: 50px!important;
}

.mt-75 {
  margin-top: 75px!important;
}

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mt-100 {
  margin-top: 100px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-16 {
  margin-top: 16px;
}

.color-c4 {
  color: #c4c4c4;
}

.pl-10 {
  padding-left: 10px;
}

.green-border {
  border: 1px solid #50D100;
}
