.workplace-card-img {
    max-height: 67px;
    object-fit: cover;
}

.Rectangle {
    width: 15px;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 18px;
}

.iconsWorkPlaceView {
    height: 16.5px;
    object-fit: cover;
    margin-right: 11px;
}

h2.workplaceTitle {
    font-weight: 600;
    font-size: 14px;
    margin-top: 16px;
}


.phoneWorkPlaceView {
    width: 19.2px;
    height: 19.2px;
    margin-right: 8px;
}

.numberWorkPlaceView {
    width: 124px;
    font-size: 12px;
    padding-left: 8px;
}

.workplaceView {
    padding: 16px 8px;
    position: relative;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.workplaceView svg {
    margin-right: 10px;
}
