.filter-search-block {
  width: 100%;
  display: flex;
  max-width: 375px;
  margin: 24px auto;
}

.filter-search-block-item {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  border-color: var(--colors-4-2);
}

.filter-search-block-item:last-child {
  border-color: transparent;
}

.filter-search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: var(--colors-4-2);
}

.filter-search-input::placeholder {
  color: var(--colors-4-4);
}
