.onelabService {
    /* height: 48px;
    line-height: 48px;   */
    /*  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px; */
    border-bottom: 1px solid #c4c4c4;
    /* overflow-y: hidden; */
    padding: 20px 0;
}

.oneService {
    margin-bottom: 32px;
}

.onelabService:last-child {
    border-bottom: none;
}

.onelabService .labPrice {
    color: #50D100;
}

.plusIcon {
    width: 10px;
    margin-left: 20px;
}

.addressPhoneIcon {
    width: 15px;
    margin-right: 10px;
}

.labcart {
    position: relative;
    margin-top: 12px;
    z-index: 1000;
}

.labcart img {
    width: 23px;
}

.labCartDiv {
    background: #fff;
    z-index: 1000;
    border-radius: 0px 0px 16px 16px;
}

.labcart .count {
    background: #50D100;
    position: absolute;
    right: -7px;
    top: 18px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 8%);
    display: block;
    color: #fff;
    text-align: center;
}

.selectedServicesDiv {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
}

.chooselabDiv {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}