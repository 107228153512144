.labSchema {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    min-height: 54px;
}

.schemaEditPen {
    width: 20px;
    height: 19px;
    object-fit: cover;
    position: absolute;
    right: 40px;
    top: 14px;
}

.schemaRemove {
    width: 17px;
    height: 17px;
    object-fit: cover;
    position: absolute;
    right: 10px;
}

.schemaEditPlus {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
}