.one-med-card-block {
  margin: 116px auto 82px;
}

.one-med-card-item {
  width: 100%;
  max-width: 375px;
  margin: 16px auto;
}

.one-med-card-item-title {
  margin-bottom: 16px;
  color: var(--colors-4-2);
}

.diagnos-card-block {
  display: flex;
  width: 100%;
  overflow: auto;
  max-width: 375px;
  column-gap: 16px;
  height: fit-content;
  white-space: nowrap;
  scrollbar-width: none;
  margin: 28px auto 24px;
  -ms-overflow-style: none;
  padding: 10px 1px 1px 1px;
}

.one-med-card-modal-content {
  display: flex;
  width: 310px;
  padding: 1px;
  row-gap: 24px;
  flex-direction: column;
}

.one-med-card-modal-title {
  margin: 0px auto;
  text-align: center;
  color: var(--colors-4-2);
}

.one-med-card-modal-textarea-block {
  width: 100%;
  height: 150px;
}
