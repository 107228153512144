.new-departments-block {
  width: 100%;
  max-width: 375px;
  display: flex;
  width: 344px;
  row-gap: 16px;
  column-gap: 16px;
  flex-wrap: wrap;
}

.card-services-more-block {
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: center;
}

.card-services-doctors-block {
  display: flex;
  width: 100%;
  margin-top: 25px;
  row-gap: 40px;
  flex-direction: column;
}

.card-services-doctors-list-title {
  color: var(--colors-4-2);
  margin-bottom: 16px !important;
}

.card-services-doctors-list-horizontal-block {
  padding: 1px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  column-gap: 16px;
  white-space: nowrap;
  scrollbar-width: none;
}
