.applications-block {
  width: 100%;
  max-width: 375px;
  margin: 75px auto 82px;
}

.applications-card-parent-block {
  margin-bottom: 24px;
}

.applications-card-date-block {
  margin-bottom: 16px;
}

.applications-card-date-text {
  color: var(--colors-4-2);
}

.applications-card-horizontal-scroll-block {
  display: flex;
  padding: 1px;
  overflow-x: auto;
  height: fit-content;
  overflow-y: hidden;
  column-gap: 24px;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.applications-card-horizontal-scroll-block::-webkit-scrollbar {
  display: none;
}

.applications-modal-block {
  text-align: center;
  padding: 0px 11.5px;
  box-sizing: border-box;
  color: var(--colors-4-2);
}

.applications-modal-title {
  text-align: center;
  margin-bottom: 16px;
  color: var(--colors-4-2);
}

.applications-search-block {
  display: flex;
  align-items: center;
}
