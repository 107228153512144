.loader-container {
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
