.histories-template-block {
  width: 100%;
  display: flex;
  row-gap: 12px;
  padding: 16px;
  max-width: 375px;
  margin: 82px auto 0px;
  box-sizing: border-box;
  flex-direction: column;
}

.histories-template-cards-parent-block {
  width: 100%;
  padding: 1px;
  display: flex;
  row-gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  flex-direction: column;
  height: calc(100vh - 240px);
}

.histories-template-add-btn-block {
  width: 100%;
  height: 40px;
}

.histories-template-no-result-block {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.histories-template-no-result-back-block {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
