.footer {
    height: 64px;
    background-color: var(--white);
    padding-top: 8px;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px 8px 0px 0px;
    -moz-border-radius: 8px 8px 0px 0px;
    -ms-border-radius: 8px 8px 0px 0px;
    -o-border-radius: 8px 8px 0px 0px;
}

.icon {
    height: 48px;
    width: 48px;
}

.icon:hover {
    border-radius: 8px 8px 8px 8px;
    background-color: var(--colors-1-1);
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 8px 8px 8px 8px;
    -moz-border-radius: 8px 8px 8px 8px;
    -ms-border-radius: 8px 8px 8px 8px;
    -o-border-radius: 8px 8px 8px 8px;
}
