.login-block {
  display: flex;
  width: 375px;
  padding: 10px 16px;
  align-items: center;
  margin: 116px auto 0px;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  height: calc(100vh - 116px);
}

.login-form-block {
  width: 100%;
  margin-top: 40px;
}

.login-form-item {
  width: 100%;
  margin-bottom: 24px;
}

.login-form-item:last-child {
  margin-bottom: 20.5px;
}

.forgot-password-block {
  display: flex;
}

.forgot-password-text {
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
  font-family: Monsterat;
  color: var(--colors-4-2);
}

.forgot-password-link {
  font-size: 14px;
  font-weight: 400;
  font-family: Monsterat;
  color: var(--colors-1-1);
}

.button-block {
  width: 231px;
  height: 40px;
  margin: 64px auto 0px;
}

.login-announcement-block {
  width: 100%;
  margin-top: 76px;
}

.login-announcement-text {
  width: 311px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 17.07px;
  font-family: Monsterat;
  color: var(--colors-3-1);
}
