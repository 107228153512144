.doctorPatient {
    display: flex;
    width: 163.5px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    border-radius: 8px;
/* Card Effect */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.doctorPatientImg {
    max-height: 86px;
    object-fit: cover;
    float: left;
}