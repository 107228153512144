.diagnosis-list-parent-block {
  width: 100%;
  max-width: 375px;
  margin: 116px auto 81px;
}

.diagnosis-list-block {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 16px;
}

.diagnosis-fix-btn-block {
  position: fixed;
  top: 152px;
  right: 10px;
  width: 180px;
  height: 40px;
  z-index: 999;
  overflow: hidden;
}

.diagnosis-list-block-back-btn-block {
  position: absolute;
  top: 0px;
  left: -40px;
  width: 24px;
  height: 24px;
  z-index: 9;
  cursor: pointer;
}

.diagnosis-list-block-back-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
