.add-request-block {
  width: 100%;
  max-width: 375px;
  margin: 92px auto 2px;
}

.add-request-search-block {
  width: 100%;
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.add-request-title {
  color: var(--colors-4-2);
}

.add-request-btn-place {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 40px;
}

.add-request-btn-place-center {
  gap: 15px;
  width: 100%;
  display: flex;
  max-width: 375px;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
}

.add-request-btn-place-item {
  width: 164px;
  height: 40px;
}

.date-time-block {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--colors-4-4);
}

.date-time-text {
  color: var(--colors-4-4);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  font-family: Monsterat;
}

.doctorPatient {
  gap: 16px;
  display: flex;
  padding: 16px;
  width: 163.5px;
  min-height: 170px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-sizing: border-box;
}

.doctorPatientImg {
  max-height: 86px;
  object-fit: cover;
  float: left;
}

.back-arrow-block {
  display: flex;
  width: 8px;
  height: 14px;
}

.back-arrow {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.add-req-services-block {
  overflow: auto;
  height: calc(100vh - 270px);
}

.add-req-patients-block {
  overflow: auto;
  height: calc(100vh - 190px);
}
