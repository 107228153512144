.diagnos-list-card {
  display: flex;
  width: 100%;
  column-gap: 8px;
  max-width: 375px;
  padding: 16px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.diagnos-list-card-roman-block {
  display: flex;
  width: 40px;
  align-items: flex-start;
  justify-content: center;
}

.diagnos-list-card-roman-number {
  color: var(--colors-1-1);
}

.diagnos-list-card-info-block {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.diagnos-list-card-info-title {
  color: var(--colors-4-2);
}

.diagnos-list-card-info-code {
  color: var(--colors-2-1);
}
