.templates-card-block {
    display: flex;
    width: 100%;
    overflow: auto;
    max-width: 375px;
    column-gap: 16px;
    margin: 0px auto;
    height: -moz-fit-content;
    height: fit-content;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 1px 1px 1px;
}
.advice-blocks {
    display: flex;
    width: 100%;
    padding: 1px;
    margin-top: 20px;
    overflow-x: auto;
    height: fit-content;
    overflow-y: hidden;
    column-gap: 24px;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
