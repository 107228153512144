.doctor-row {
  margin: 116px auto 82px !important;
}

.img {
  width: 107px;
  height: 107px;
}

.vector1 {
  width: 8px;
  height: 14px;

}

.iconsDiv .calendarIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
}

.iconsDiv .bookmarkIcon {
  width: 15px;
  height: 22px;
  top: 50px;
  position: absolute;
  left: 16px;
}

.iconsDiv .shareIcon {
  width: 17px;
  height: 18px;
  position: absolute;
  top: 90px;
  left: 15px;
}

.personName {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 12px;
  color: #152534;
  margin-bottom: 8px;
}

.person {
  text-align: center;
}

.iconsDiv {
  position: relative;
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.icon5 {
  width: 14px;
  margin-left: 17px;
  margin-top: 14px;
}

.inline {
  display: inline;
}

.inlineCenter {
  text-align: center;
  margin-bottom: 8px;
}

.icon4 {
  width: 19.2px;
  height: 19.2px;
  margin-right: 10.4px;
}


.locationIcon {
  height: 16px;
  margin-right: 10px;
}

.fw-500 {
  font-weight: 500;
}

.arrow {
  width: 14px;
  height: 8px;
  margin-top: 15px;
}

.h56 {
  height: 54px;
  overflow: hidden;
}

.workplacesDiv {
  padding-bottom: 26px;
  border-bottom: 0.5px solid #C4C4C4;
}

.doctorMenu {
  margin-top: 24px;
}

.doctorServiceRow {
  margin-bottom: 24px;
}

.doctorServiceRow .serviceTitle {
  font-size: 12px;
  color: #152534;
}

.doctorServiceRow .servicePrice {
  font-size: 12px;
  color: #50D100;
  ;
}

.doctorInfoDiv {
  font-size: 11px;
}

.beforeAndAfterImage {
  border-radius: 5px;
}

.doctorMenuDiv more {
  font-size: 14px;
  color: #218CC0;
}

.buttonsDiv {
  padding-top: 36px;
  padding-bottom: 23px;
}

.serviceBlockTitle {
  border-bottom: 0.5px solid #C4C4C4;
  padding-bottom: 15px;
}

.f-s-12 {
  font-size: 12px;
}

.totalTitle {
  margin-top: 40px;
  margin-bottom: 40px;
}

.dateAndTimeBlock {
  padding-bottom: 36px;
}

.detailsBlock input {
  border: 0;
  border-bottom: 0.5px solid #152534;
  border-radius: 0;
  font-size: 14px;
  color: #6A6A6A;
}

.successIcon {
  width: 40px;
  height: 40px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.addressesDiv {
  height: 43px;
  overflow: hidden;
  float: right;
}
