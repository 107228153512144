.card-services-list-item {
  display: flex;
  /* width: 152px; */
  height: 211px;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px 8px 16px 8px;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 15px;
}

.card-services-list-item-img-block {
  width: 107px;
  height: 107px;
  border-radius: 55px;
  -webkit-border-radius: 55px;
  -moz-border-radius: 55px;
  -ms-border-radius: 55px;
  -o-border-radius: 55px;
}

.card-services-list-item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 55px;
  -webkit-border-radius: 55px;
  -moz-border-radius: 55px;
  -ms-border-radius: 55px;
  -o-border-radius: 55px;
}

.card-services-list-item-doc-info {
  width: 115px;
  text-wrap: balance;
  margin-bottom: 8px !important;
  color: var(--colors-4-2);
}

.card-services-list-item-info {
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-2-1);
}