.bannerCol {
  padding-top: 16px;
  margin-top: 75px !important;
}

ul.bannerDots {
  position: relative !important;
  margin-top: 26px;
}


ul.bannerDots button {
  border-color: #218CC0 !important;
  background: #fff !important;
  margin-right: 16px !important;
  width: 8px !important;
  height: 8px !important;
}

ul.bannerDots .react-multi-carousel-dot--active button {
  background: #218CC0 !important;
}
