.filter-tabs-block {
  display: flex;
  width: 100%;
  height: 23px;
  overflow: auto;
  max-width: 375px;
  column-gap: 32px;
  margin: 0px auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 15px;
  margin-top: 10px;
}

.filter-tabs-block::-webkit-scrollbar {
  display: none;
}

.filter-tabs-item {
  padding-bottom: 9px;
  box-sizing: border-box;
  border-bottom: 2px solid;
  color: var(--colors-4-2);
  border-color: transparent;
}

.active-filter-tabs {
  border-color: var(--colors-1-1);
}
