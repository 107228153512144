.history-card-block {
  width: 215px;
  height: auto;
  display: flex;
  row-gap: 10px;
  padding: 16px;
  flex-shrink: 0;
  overflow-y: auto;
  max-height: 365px;
  overflow-x: hidden;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.history-card-date {
  color: var(--colors-2-1);
}

.history-card-name {
  color: var(--colors-4-2);
}

.history-card-profession {
  color: var(--colors-1-1);
}

.history-card-description {
  text-wrap: wrap;
  color: var(--colors-4-3);
  width: 70%;
}

.template-card-edit-icon {
  height: 40px;
  width: 40px;
}